import React, { useEffect, useState } from 'react'
import { localize } from '../api/Localize'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import Icon from '@material-ui/core/Icon'

type ErrorSnackbarProps = {
    error: string | null
}

export const ErrorSnackbar: React.FC<ErrorSnackbarProps> = (props) => {
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(props.error !== null)
    }, [props.error])

    const handleClose = () => {
        setOpen(false)
    }

        const message = localize.translate('errorOccurred') + ' ' + props.error

        return (
            <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose.bind(this)}
            >
                <SnackbarContent
                message={(
                    <span style={{ display: 'flex', alignItems: 'center', }}>
                        <Icon style={{ fontSize: 20, opacity: 0.9, marginRight: 20 }}>
                            info
                        </Icon>
                        {message}
                    </span>
                )}
                style={{ backgroundColor: '#1976d2' }}
                />
            </Snackbar>
)
}