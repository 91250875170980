import React, { Component } from 'react'
import { connect } from 'react-redux'
import { localize } from '../../api/Localize'
import { login, passwordChange, rememberMeChange, usernameChange, } from '../../actions/Actions'
import { ContentBox } from '../../views/ContentBox'
import { SupportBox } from '../../views/SupportBox'
import { Link } from 'react-router'
import { getParameterByName } from '../../utilities/Utils'
import { AutofillCorrectingTextField } from '../../views/AutofillCorrectingTextField'
import { LoginStatus } from '../../api/LoginStatus'
import { LoginState } from 'reducers/LoginReducer'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'


class LoginRoute extends Component<({ login: LoginState } & DispatchMethods), { passwordVisible: boolean }> {
    private readonly passwordRef: React.RefObject<any>

    constructor(props) {
        super(props)
        this.onLogIn = this.onLogIn.bind(this)
        this.onKeyPress = this.onKeyPress.bind(this)

        this.passwordRef = React.createRef()
        this.state = {
            passwordVisible: false,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const shouldBecomeVisible = !prevState.passwordVisible && nextProps.login.loginStatus === LoginStatus.ENTER_PASSWORD
        const shouldStayVisible = prevState.passwordVisible && nextProps.login.isLoggedIn !== null
        return {
            passwordVisible: shouldBecomeVisible || shouldStayVisible,
        }
    }

    componentDidUpdate(prevProps) {
        document.getElementById('password')?.focus()
    }

    getErrorText(status) {
        if (status) {
            if (status === LoginStatus.INVALID_LOGIN) {
                return localize.translate('invalidLogin')
            }
            if (status === LoginStatus.CLOSED_CUSTOMER) {
                return localize.translate('closedCustomer')
            }
            if (status === LoginStatus.SSO_MANDATORY) {
                return localize.translate('invalidLogin')
            }
        } else {
            return ''
        }
    }

    onLogIn() {
        const username = this.props.login.username
        const password = this.props.login.password
        const rememberMe = this.props.login.rememberMe
        if (this.state.passwordVisible) {
            this.props.onLogIn(username, password, rememberMe, getParameterByName('redirectUrl'))
        } else {
            this.props.onLogIn(username, null, null, getParameterByName('redirectUrl'))
        }
    }

    onKeyPress(event) {
        if (event.charCode === 13) {
            this.onLogIn()
        }
    }

    render() {
        const error = this.props.login.error
        if (error.isError) {
            throw Error(error.err)
        }

        const isLoggingIn = this.props.login.isLoggingIn
        const isLoggedIn = this.props.login.isLoggedIn
        const redirectUrl = this.props.login.redirectUrl
        const loginStatus = this.props.login.loginStatus
        const isError = isLoggedIn != null && !isLoggedIn && loginStatus !== LoginStatus.ENTER_PASSWORD
        const passwordVisible = this.state.passwordVisible

        //Redirects when login is successful. if no redirect url is specified it redirects to Organization List
        if (redirectUrl != null) {
            window.location = redirectUrl
        }

        const rememberMeClassName = 'remember-me'

        return (
            <div>
                <SupportBox/>

                <div className="content-div">
                    <ContentBox
                    title={localize.translate('login')}
                    >

                        {/*UserName*/}
                        <div className="section">
                            <AutofillCorrectingTextField
                            autoFocus
                            id="username"
                            variant="filled"
                            error={isError}
                            disabled={isLoggingIn}
                            onKeyPress={this.onKeyPress}
                            label={localize.translate('userName')}
                            onChange={this.props.onUsernameChange}
                            fullWidth
                            margin="none"
                            />
                        </div>

                        {/*Password*/}
                        <div className={'section' + (passwordVisible ? '' : ' hidden')}>
                            <AutofillCorrectingTextField
                            id="password"
                            type="password"
                            inputRef={this.passwordRef}
                            variant="filled"
                            error={isError}
                            helperText={loginStatus != null ? this.getErrorText(loginStatus) : ''}
                            disabled={isLoggingIn}
                            onKeyPress={this.onKeyPress}
                            label={localize.translate('password')}
                            onChange={this.props.onPasswordChange}
                            fullWidth
                            margin="none"
                            />
                        </div>

                        {/*remember me*/}
                        <div className={'section ' + rememberMeClassName + (passwordVisible ? '' : ' hidden')}>
                            <FormControlLabel
                            control={(
                                <Checkbox
                                    onKeyPress={this.onKeyPress}
                                    onChange={this.props.onRememberMe}
                                    color="primary"
                                />
                            )}
                            disabled={isLoggingIn}
                            label={localize.translate('rememberMe')}
                            />
                        </div>


                        {/*log in*/}
                        <div className="section">
                            <Button
                            variant={'contained'}
                            className="login-button"
                            id="login"
                            // labelposition="before"
                            fullWidth
                            disabled={isLoggingIn}
                            onClick={this.onLogIn}
                            >
                                {passwordVisible ? localize.translate('login') : localize.translate('next')}
                            </Button>
                        </div>

                        <div className="footer">
                            {/*forgot password*/}
                            <div className="footer-link">
                                <Link className="link"
                                  to={!isLoggingIn ? window.forgotUrl : ''}
                                >
                                    {localize.translate('forgotPassword')}
                                </Link>
                            </div>
                        </div>
                    </ContentBox>
                </div>
            </div>
        )
    }
}

export const View = LoginRoute

const mapStateToProps = (state, ownProps) => {
    return {
        login: state.login,
    }
}

interface DispatchMethods {
    onPasswordChange: (event) => void,
    onUsernameChange: (event) => void,
    onRememberMe: (event, checked) => void,
    onLogIn: (username: string, password: string | null, rememberMe: string | null, parameterByName: null | string) => void,
}

const mapDispatchToProps = (dispatch, ownProps): DispatchMethods => {
    return {
        onPasswordChange: (event) => {
            const password = event.target.value
            return dispatch(passwordChange(password))
        },
        onUsernameChange: (event) => {
            const username = event.target.value
            return dispatch(usernameChange(username))
        },
        onRememberMe: (event, checked) => {
            return dispatch(rememberMeChange(checked))
        },
        onLogIn: (username, password, rememberMe, redirectUrl) => {
            return dispatch(login(username, password, rememberMe, redirectUrl))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(View)