import React from 'react'
import { MenuBar } from './MenuBar'
import Paper from '@material-ui/core/Paper'
import { SupportLinks } from './SupportLinks'

type ContentBoxProps = {
    title: string
}

export const ContentBox: React.FC<ContentBoxProps> = (props) => {
    const classname = 'content-box'

    return (
        <div className={classname}>

            <Paper className="paper">
                <div className="inner-content">
                    {/*Title*/}
                    <div className="header">
                        <h1>{props.title}</h1>
                    </div>

                    {/* Render the content in the box */}
                    {props.children}
                </div>
            </Paper>

            <MenuBar/>

            <SupportLinks/>
        </div>
    )
}