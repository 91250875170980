import { ActionNames } from '../api/Settings'
import { LoginStatus } from '../api/LoginStatus'

export interface LoginState {
    username: string,
    error: {isError: boolean, err: string},
    password: string,
    rememberMe: string,
    isLoggingIn: boolean,
    isLoggedIn: boolean,
    redirectUrl: (string | Location) & Location,
    email: string,
    loginStatus: string,
}

const initialState = {
    username   : '',
    error      : { isError: false, err: '' },
    password   : null,
    rememberMe : false,
    isLoggingIn: false,
    isLoggedIn : null,
    redirectUrl: null,
    email      : '',
    loginStatus: null,
}

export const login = (state = initialState, action) => {
    switch (action.type) {
        case ActionNames.CHANGE_USERNAME:
            return Object.assign({}, state, {
                username   : action.username,
                loginStatus: null,
                isLoggedIn : null,
            })

        case ActionNames.ERROR:
            return Object.assign({}, state, { error: { isError: true, err: action.err } })

        case ActionNames.IS_LOGGING_IN:
            return Object.assign({}, state, { isLoggingIn: action.payload.isLoggingIn })

        case ActionNames.CHANGE_PASSWORD:
            return Object.assign({}, state, {
                password   : action.password,
                loginStatus: state.loginStatus === LoginStatus.ENTER_PASSWORD ? LoginStatus.ENTER_PASSWORD : null
            })

        case ActionNames.CHANGE_REMEMBER_ME:
            return Object.assign({}, state, { rememberMe: action.rememberMe })

        case ActionNames.LOGGED_IN:
            return Object.assign(
                {},
                state,
                {
                    isLoggedIn : action.result.successful,
                    redirectUrl: action.result.redirectUrl,
                    loginStatus: action.result.loginStatus
                })

        case ActionNames.CHANGE_EMAIL:
            return Object.assign({}, state, { email: action.email })

        default:
            return state
    }
}