import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ContentBox } from '../../views/ContentBox'
import { SupportBox } from '../../views/SupportBox'
import { localize } from '../../api/Localize'
import { getParameterByName } from '../../utilities/Utils'
import { sendVerificationKey, verificationKeyChange } from '../../actions/Actions'
import Button from '@material-ui/core/Button'
import { AutofillCorrectingTextField } from '../../views/AutofillCorrectingTextField'
import { Link } from 'react-router'
import { VerificationState } from '../../reducers/VerificationReducer'


class NewPasswordRoute extends Component<({verification: VerificationState} & DispatchProps)> {
    constructor (props) {
        super(props)
        const verificationKey = getParameterByName('code')
        if (verificationKey) {
            this.props.onVerificationKeyChange(verificationKey)
            this.props.onSendVerificationKey(verificationKey)
        }
    }


    render () {

        const error = this.props.verification.error
        if (error.isError) {
            throw Error(error.err)
        }

        const isSending = this.props.verification.isSending
        const verificationKey = this.props.verification.verificationKey
        const redirectUrl = this.props.verification.redirectUrl
        const onKeyPress = (event) => {
            if (event.charCode == 13) {
                this.props.onSendVerificationKey(verificationKey)
            }
        }

        if (redirectUrl != null) {
            window.location = redirectUrl
        }

        return (
            <div>
                <SupportBox/>

                <div className="content-div">

                    <ContentBox title={localize.translate('verificationHeader')}>

                        <div className="section">
                            <p>{localize.translate('verificationInfoText')}</p>
                        </div>

                        {/*verification code*/}
                        <div className="section">
                            <AutofillCorrectingTextField
                            id="verification"
                            variant="filled"
                            disabled={isSending}
                            onKeyPress={onKeyPress}
                            label={localize.translate('verificationKey')}
                            value={verificationKey}
                            onChange={(event) => this.props.onVerificationKeyChange(event.target.value)}
                            fullWidth
                            margin="none"
                            />
                        </div>

                        {/*Send*/}
                        <div className="section">
                            <Button
                            variant="contained"
                            className="login-button"
                            // labelposition={'before'}
                            // primary={true}
                            fullWidth
                            disabled={isSending}
                            onClick={() => this.props.onSendVerificationKey(verificationKey)}
                            >
                                {localize.translate('send')}
                            </Button>
                        </div>

                        <div className="footer">
                            <div className="footer-link">
                                <Link className="link"
                                  to={window.baseUrl}
                                >
                                    {localize.translate('goToLogin')}
                                </Link>
                            </div>
                        </div>

                    </ContentBox>
                </div>
            </div>
        )
    }
}

export const View = NewPasswordRoute

const mapStateToProps = (state, ownProps) => {
    return {
        verification: state.verification,
    }
}

interface DispatchProps {
    onVerificationKeyChange: (verificationKey) => void
    onSendVerificationKey: (verificationKey) => void
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onVerificationKeyChange: (verificationKey) => {
            return dispatch(verificationKeyChange(verificationKey))
        },
        onSendVerificationKey  : (verificationKey) => {
            return dispatch(sendVerificationKey(verificationKey))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(View)