import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ContentBox } from '../../views/ContentBox'
import { SupportBox } from '../../views/SupportBox'
import { localize } from '../../api/Localize'
import {
   changePassword,
   getUserFromContext,
   passwordChange,
   repeatPasswordChange,
   toggleRulesDialog
} from '../../actions/Actions'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import AccountIcon from '@material-ui/icons/AccountCircle'
import { AutofillCorrectingTextField } from '../../views/AutofillCorrectingTextField'
import { Link } from 'react-router'
import { PasswordRulesDialog } from '../../views/PasswordRulesDialog'
import { NewPasswordState } from '../../reducers/NewPasswordReducer'
import { ErrorSnackbar } from '../../views/ErrorSnackbar'

class NewPasswordRoute extends Component<({newPassword: NewPasswordState} & DispatchProps), {rulesDialogOpen: boolean}> {
   constructor (props) {
      super(props)
      this.props.getCurrentSessionUser()
      this.state = { rulesDialogOpen: false }
   }

   render () {

      const error = this.props.newPassword.error
      if (error.isError) {
         if (error.err === localize.translate('singleSignonError') || error.err === localize.translate('illegalPasswordError')) {
            // do nothing for now, we will show a snackbar with the error message
         } else {
            throw Error(error.err)
         }
      }

      const password = this.props.newPassword.password
      const repeatPassword = this.props.newPassword.repeatPassword
      const isSending = this.props.newPassword.isSending
      const redirectUrl = this.props.newPassword.redirectUrl
      const username = this.props.newPassword.username
      const rulesDialogOpen = this.props.newPassword.rulesDialogOpen
      const passwordWarning = password == null || password.trim().length > 0 ? null : localize.translate('passwordError')
      const repeatPasswordWarning = repeatPassword == null || repeatPassword === password ? null : localize.translate('repeatPasswordError')
      const onKeyPress = (event) => {
         if (event.charCode == 13) {
            this.props.onChangePassword(password, repeatPassword)
         }
      }

      if (redirectUrl != null) {
         window.location = redirectUrl
      }

      return (
          <div>
              <SupportBox/>

              <div className="content-div">

                  <ContentBox title={localize.translate('newPassword')}>

                      {/*The user chip*/}
                      {username ?
                          <Chip
                           label={username}
                           avatar={<Avatar><AccountIcon/></Avatar>}
                           className="user-chip"
                          /> : ''}

                      <div className="section">
                          <PasswordRulesDialog open={rulesDialogOpen} toggleRulesDialog={this.props.toggleRulesDialog}/>
                      </div>

                      {/*Password*/}
                      <div className="section">
                          <AutofillCorrectingTextField
                        id="new-password"
                        type="password"
                        variant="filled"
                        error={passwordWarning != null}
                        helperText={passwordWarning != null ? passwordWarning : ''}
                        disabled={isSending}
                        onKeyPress={onKeyPress}
                        label={localize.translate('newPassword')}
                        onChange={this.props.onPasswordChange}
                        autoComplete="new-password"
                        fullWidth
                        margin="none"
                          />
                      </div>

                      {/*Repeat Password*/}
                      <div className="section">
                          <AutofillCorrectingTextField
                        id="new-password-repeat"
                        type="password"
                        variant="filled"
                        error={repeatPasswordWarning != null}
                        helperText={repeatPasswordWarning != null ? repeatPasswordWarning : ''}
                        disabled={isSending}
                        onKeyPress={onKeyPress}
                        label={localize.translate('repeatPassword')}
                        onChange={this.props.onRepeatPasswordChange}
                        autoComplete="new-password"
                        fullWidth
                        margin="none"
                          />
                      </div>


                      {/*Send*/}
                      <div className="section">
                          <Button
                        variant="contained"
                        className="login-button"
                        // labelposition={'before'}
                        fullWidth
                        disabled={isSending}
                        onClick={() => this.props.onChangePassword(password, repeatPassword)}
                          >
                              {localize.translate('send')}
                          </Button>
                      </div>

                      <div className="footer">
                          <div className="footer-link">
                              <Link className="link"
                           to={window.baseUrl}
                              >
                                  {localize.translate('goToLogin')}
                              </Link>
                          </div>
                      </div>

                  </ContentBox>

                  <ErrorSnackbar error={error.err}/>

              </div>
          </div>
      )
   }

}

export const View = NewPasswordRoute

const mapStateToProps = (state, ownProps) => {
   return {
      newPassword: state.newPassword,
   }
}

interface DispatchProps {
   onPasswordChange: (event) => void
   onRepeatPasswordChange: (event) => void
   onChangePassword: (password, repeatPassword) => void
   getCurrentSessionUser: () => void
   toggleRulesDialog: () => void
}

const mapDispatchToProps = (dispatch, ownProps) => {
   return {
      onPasswordChange      : (event) => {
         const password = event.target.value
         return dispatch(passwordChange(password))
      },
      onRepeatPasswordChange: (event) => {
         const repeatPassword = event.target.value
         return dispatch(repeatPasswordChange(repeatPassword))
      },
      onChangePassword      : (password, repeatPassword) => {
         dispatch(changePassword(password, repeatPassword))
      },
      getCurrentSessionUser : () => {
         dispatch(getUserFromContext())
      },
      toggleRulesDialog     : () => {
         dispatch(toggleRulesDialog())
      }
   }
}

export default connect(mapStateToProps, mapDispatchToProps)(View)