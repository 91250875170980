import { ContentBox } from '../../views/ContentBox'
import { SupportBox } from '../../views/SupportBox'
import React, { Component } from 'react'
import { getParameterByName } from '../../utilities/Utils'
import { localize } from '../../api/Localize'
import { Link } from 'react-router'


export default class ResetPasswordResultRoute extends Component {
    render () {
        return (
            <div>
                <SupportBox/>

                <div className="content-div">
                    <ContentBox title={localize.translate('PasswordResetResultInfoHeader')}>
                        <div className="section">
                            <p>{localize.translate('PasswordResetResultInfoText1').replace('{0}', getParameterByName('email') as string)}</p>
                            <p>{localize.translate('PasswordResetResultInfoText2')}</p>
                            <p>{localize.translate('PasswordResetResultInfoText3')}</p>
                        </div>

                        <div className="footer">
                            <div className="footer-link">
                                <Link className="link"
                                  to={window.baseUrl}
                                >
                                    {localize.translate('goToLogin')}
                                </Link>
                            </div>
                        </div>
                    </ContentBox>

                </div>
            </div>
        )
    }
}