import React from 'react'
import { localize } from '../api/Localize'
import Dialog from '@material-ui/core/Dialog'

import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import HelpOutline from '@material-ui/icons/HelpOutline'
import withMobileDialog from '@material-ui/core/withMobileDialog'

type PasswordRulesDialogProps = {
    toggleRulesDialog: () => void,
    open: boolean,
    fullScreen?: boolean
}

export const PasswordRulesDialog: React.FC<PasswordRulesDialogProps> = (props) => {

        return (
            <div className="rules-dialog">
                {localize.translate('newPasswordInfoText')}
                <IconButton onClick={props.toggleRulesDialog}>
                    <HelpOutline/>
                </IconButton>
                <Dialog
                    open={props.open}
                    onClose={props.toggleRulesDialog}
                    fullScreen={props.fullScreen}
                >
                    <DialogTitle>{localize.translate('HeaderRules')}</DialogTitle>
                    <DialogContent>
                        <div className="rules-list">
                            <ul>
                                <li> {localize.translate('Rule1')} </li>
                                <li> {localize.translate('Rule2')} </li>
                                <li> {localize.translate('Rule3')}
                                    <ul>
                                        <li> {localize.translate('Rule3a')} </li>
                                        <li> {localize.translate('Rule3b')} </li>
                                        <li> {localize.translate('Rule3c')} </li>
                                        <li> {localize.translate('Rule3d')} </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.toggleRulesDialog} color="primary">{localize.translate('okay')}</Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
}


// export default withMobileDialog()(PasswordRulesDialog as any); //TODO look at as any after bumping MUI