import { ActionNames } from '../api/Settings'

const initialState = {
    error: { isError: false, err: '' },
    verificationKey: null,
    isSending: false,
    redirectUrl: null,
    success: true,
}

export interface VerificationState {
    error: {isError: boolean, err: string},
    verificationKey: string,
    isSending: boolean,
    redirectUrl: (string | Location) & Location,
    success: boolean,
}

export const verification = (state = initialState, action) => {
    switch (action.type) {
        case ActionNames.ERROR:
            return Object.assign({}, state, { error: { isError: true, err: action.err } })

        case ActionNames.CHANGE_VERIFICATION_KEY:
            return Object.assign({}, state, { verificationKey: action.verificationKey, success: true })

        case ActionNames.GOT_VERIFICATION_KEY:
            return Object.assign({}, state, { redirectUrl: action.result.redirectUrl, success: action.result.successful })

        case ActionNames.IS_SENDING:
            return Object.assign({}, state, { isSending: action.payload.isSending })

        default:
            return state
    }
}