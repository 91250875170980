import React from 'react'
import { localize } from '../api/Localize'
import { bool } from 'prop-types'

export const MenuBar: React.FC = () => {
    return (
        <div className="menu-bar">
            <a href={localize.translate('newsUrl')} target="_blank" rel="noreferrer">{localize.translate('news')}</a>
            {!window.helpCenterFeatureToggled && <div>|</div>}
            {!window.helpCenterFeatureToggled && <a href={localize.translate('userManualUrl')} target="_blank" rel="noreferrer">{localize.translate('userManual')}</a>}
            <div>|</div>
            <a href={localize.translate('coursesUrl')} target="_blank" rel="noreferrer">{localize.translate('courses')}</a>
            <div>|</div>
            <a href={localize.translate('additionalServicesUrl')} target="_blank" rel="noreferrer">{localize.translate('additionalServices')}</a>
        </div>
    )
}