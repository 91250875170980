import React from 'react'
import { render } from 'react-dom'
import { applyMiddleware, createStore } from 'redux'
import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import { onRouteChange } from './actions/Router'
import { reducers } from './reducers/MainReducer'
import * as Localize from './api/Localize'
import '../less/Main.less'
import XactAnalyticsWrapper from './utilities/XactAnalyticsWrapper'
import { browserHistory } from 'react-router'
import MainContainer from './containers/MainContainer'



// @ts-ignore
window.localize = Localize.localize
// @ts-ignore
if (XactAnalytics) {
    // @ts-ignore
    window.XactAnalyticsWrapper = new XactAnalyticsWrapper(XactAnalytics)
} else {
    // @ts-ignore
    window.XactAnalyticsWrapper = new XactAnalyticsWrapper(null)
}

const middleware = [thunkMiddleware]
// if (process.env.NODE_ENV !== 'production') {
//     middleware = [...middleware, createLogger()]
// }

Localize
    .loadAll()
    .then(
        function () {
            const store = createStore(
                reducers,
                applyMiddleware(...middleware)
            )
            browserHistory.listen(location => store.dispatch(onRouteChange(location)))
            render(
                <Provider store={store}>
                    <MainContainer/>
                </Provider>,
                document.getElementById('react-login-root')
            )
        }
    )

/**
 * Declares global window variables that are defined in index.vm
 */
declare global {
    interface Window {
        baseUrl: string;
        SSOVerificationUrl: string;
        forgotUrl: string;
        resetResultUrl: string;
        backoffUrl: string;
        newPasswordUrl: string;
        verificationUrl: string;
        userLoginExpiredUrl: string;
        helpCenterFeatureToggled: boolean;
    }
}