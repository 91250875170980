import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import { Button } from '@material-ui/core'
import { localize } from '../api/Localize'
import React from 'react'

type ConfirmationDialogProps = {
    open: boolean
    text: string
    onClose: () => void
    onAuthSubmit: () => void
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props) => {
    return (
        <Dialog open={props.open}
                   disableBackdropClick
                   disableEscapeKeyDown
        >
            <DialogTitle id="confirmation-dialog-title">{localize.translate('confirmTitle')}</DialogTitle>
            <DialogContent>
                {props.text}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()} color="primary">
                    {localize.translate('cancel')}
                </Button>
                <Button
                onClick={() => {
                    props.onClose()
                    props.onAuthSubmit()
                }}
                color="primary"
                >
                    {localize.translate('ok')}
                </Button>
            </DialogActions>
        </Dialog>
)
}