import React, { Component } from 'react'
import { connect } from 'react-redux'
import { localize } from '../../api/Localize'
import { getUserData, passwordChange, sendNewPasswordMailSSO, verifySSO, } from '../../actions/Actions'
import { ContentBox } from '../../views/ContentBox'
import { SupportBox } from '../../views/SupportBox'
import AlertSnackbar from '../../views/AlertSnackbar'
import { getParameterByName } from '../../utilities/Utils'
import { AutofillCorrectingTextField } from '../../views/AutofillCorrectingTextField'
import { ConfirmationDialog } from '../../views/ConfirmationDialog'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import { LoginStatus } from '../../api/LoginStatus'
import XactAnalyticsWrapper from '../../utilities/XactAnalyticsWrapper'
import { SSOVerificationState } from '../../reducers/SSOVerificationReducer'
import { Button } from '@material-ui/core'

class SSOVerificationRoute extends Component<({ ssoVerification: SSOVerificationState } & DispatchMethods), { forgotPasswordDialogueOpen: boolean }> {

    username;
    state;
    private readonly passwordRef: React.RefObject<any> | undefined

    constructor (props) {
        super(props)
        this.onContinue = this.onContinue.bind(this)
        this.onClickContinue = this.onClickContinue.bind(this)
        this.onForgotPasswordClick = this.onForgotPasswordClick.bind(this)
        this.onForgotPasswordCloseDialogue = this.onForgotPasswordCloseDialogue.bind(this)
        this.onForgotPasswordConfirmDialogue = this.onForgotPasswordConfirmDialogue.bind(this)
        // this.resetAlertState = this.resetAlertState.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this)
        this.username = getParameterByName('username')
        this.state = {
            forgotPasswordDialogueOpen: false
        }
    }

    componentDidMount () {
        this.props.getUserData(this.username)
    }

    onContinue () {
        const username = this.username
        const password = this.props.ssoVerification.password
        const redirectUrl = getParameterByName('redirectUrl')
        this.props.onContinue(username, password, redirectUrl)
    }

    onClickContinue () {
        this.onContinue()
        // @ts-ignore
        XactAnalyticsWrapper.event('CLICK', 'sso-verification-login', 'SUBMIT')
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        const passwordField = document.getElementById('password')
        if (passwordField != null) {
            passwordField.focus()
        }
    }

    // resetAlertState () {
    //     this.resetAlertState();
    // }

    onForgotPasswordConfirmDialogue () {
        this.props.onForgotPasswordConfirmDialogue(this.props.ssoVerification.email)
    }

    onForgotPasswordClick () {
        this.setState({ forgotPasswordDialogueOpen: true })
    }

    onForgotPasswordCloseDialogue () {
        this.setState({ forgotPasswordDialogueOpen: false })
    }

    getErrorText (status) {
        if (status) {
            if (status === LoginStatus.INVALID_LOGIN) {
                return localize.translate('invalidPassword')
            }
        } else {
            return ''
        }
    }

    onKeyPress (event) {
        if (event.charCode === 13) {
            this.onContinue()
            // @ts-ignore
            XactAnalyticsWrapper.event('KEYPRESS', 'sso-verification-login', 'SUBMIT')
        }
    }

    render () {

        const isLoggingIn = this.props.ssoVerification.isLoggingIn
        const loginAttemptSuccessful = this.props.ssoVerification.loginAttemptSuccessful
        const loginStatus = this.props.ssoVerification.loginStatus
        const isError = loginAttemptSuccessful != null && !loginAttemptSuccessful && loginStatus !== LoginStatus.ENTER_PASSWORD
        const redirectUrl = this.props.ssoVerification.redirectUrl

        if (redirectUrl != null) {
            window.location = redirectUrl
        }

        //An error was thrown on login attempt, but we're not sure what happened, so just go to error page
        const error = this.props.ssoVerification.error
        if (error.isError) {
            throw Error(error.err)
        }

        return (
            <div>
                <SupportBox/>

                <div className="content-div">
                    {this.props.ssoVerification.isSending &&
                        <Grid container justify="space-between">
                            <div/>
                            <CircularProgress/>
                            <div/>
                        </Grid>
                    }
                    {!this.props.ssoVerification.isSending &&
                        <ContentBox
                                title={localize.format('confirmSSOUserHeading')}
                        >
                            <p>{localize.format('confirmSSOUserInfoText1', this.props.ssoVerification.companyName)}</p>
                            <p>{localize.translate('confirmSSOUserInfoText2')}</p>

                            {/*username*/}
                            <div className="section">
                                <AutofillCorrectingTextField
                                      autoFocus
                                      id="username"
                                      variant="filled"
                                      error={false}
                                      disabled
                                      onKeyPress={this.onKeyPress}
                                      label={localize.translate('userName')}
                                      fullWidth
                                      margin="none"
                                      value={this.username}
                                />
                            </div>

                            {/*Password*/}
                            <div className={'section'}>
                                <AutofillCorrectingTextField
                                      id="password"
                                      type="password"
                                      ref={this.passwordRef}
                                      variant="filled"
                                      error={isError}
                                      helperText={loginStatus != null ? this.getErrorText(loginStatus) : ''}
                                      disabled={isLoggingIn}
                                      onKeyPress={this.onKeyPress}
                                      label={localize.translate('password')}
                                      onChange={this.props.onPasswordChange}
                                      fullWidth
                                      margin="none"
                                />
                            </div>

                            {/*next step*/}
                            <div className="section">
                                <Button
                                      variant={'contained'}
                                      className="login-button"
                                      id="sso-verification-login"
                                      // labelposition="before"
                                      fullWidth
                                      disabled={isLoggingIn}
                                      onClick={this.onClickContinue}
                                >
                                    {localize.translate('next')}
                                </Button>
                            </div>

                            <div className="footer">
                                {/*forgot password*/}
                                <div className="footer-link">
                                    <a className="link selectable" onClick={this.onForgotPasswordClick}>
                                        {localize.translate('forgotPasswordOnlyPassword')}
                                    </a>
                                </div>
                                {this.state.forgotPasswordDialogueOpen &&
                                    <ConfirmationDialog
                                             text={localize.format('sendPasswordConfirmationText', this.props.ssoVerification.email)}
                                             onAuthSubmit={this.onForgotPasswordConfirmDialogue}
                                             onClose={this.onForgotPasswordCloseDialogue}
                                             open={this.state.forgotPasswordDialogueOpen}
                                    />
                                 }
                            </div>
                        </ContentBox>
                    }
                    <AlertSnackbar/>
                </div>
            </div>
        )
    }
}

export const View = SSOVerificationRoute

const mapStateToProps = (state) => {
    return {
        ssoVerification: state.ssoVerification,
    }

}

export interface DispatchMethods {
    onPasswordChange: (event) => void,
    onContinue: (username, password, redirectUrl) => void,
    getUserData: (username) => void,
    onForgotPasswordConfirmDialogue: (email) => void,
}

const mapDispatchToProps = (dispatch) => {
    return {
        onPasswordChange               : (event) => {
            const password = event.target.value
            return dispatch(passwordChange(password))
        },
        onContinue                     : (username, password, redirectUrl) => {
            return dispatch(verifySSO(username, password, redirectUrl))
        },
        getUserData                    : (username) => {
            return dispatch(getUserData(username))
        },
        onForgotPasswordConfirmDialogue: (email) => {
            return dispatch(sendNewPasswordMailSSO(email))
        }
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(View)