export default class XactAnalyticsWrapper {
    XactAnalytics;

    constructor (XactAnalytics) {
        this.XactAnalytics = XactAnalytics
    }

    xactAnalyticsInitialised () {
        return !!this.XactAnalytics
    }

    event (event, component, action) {
        if (this.xactAnalyticsInitialised()) {
            this.XactAnalytics.event('LOGIN', component, event, action)
        } else {
            console.warn('XactAnalytics is not initialised!')
        }
    }
}