import { combineReducers, Reducer } from 'redux'
import { login } from './LoginReducer'
import { forgot } from './ForgotReducer'
import { newPassword } from './NewPasswordReducer'
import { verification } from './VerificationReducer'
import ssoVerification from './SSOVerificationReducer'
import { alert } from './AlertReducer'

export const reducers = combineReducers(
    {
        login,
        forgot,
        newPassword,
        verification,
        ssoVerification,
        alert
    }
)