import React from 'react'
import { localize } from '../api/Localize'
import ContactSupport from '@material-ui/icons/ContactSupport'

export const SupportLinks: React.FC = () => {
    return (
        <div className='support-links'>
            {/*support*/}
            <a href={localize.translate('supportURL')}>
                <ContactSupport className="material-icons"/>
                <span>{localize.translate('support')}</span>
            </a>
        </div>
    )
}