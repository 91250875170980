import ActionNames from '../api/ActionNames'

const initialState = {
    show     : false,
    message  : '',
    isSuccess: null,
}


export const alert = (state = initialState, action) => {
    switch (action.type) {
        case ActionNames.SET_ALERT:
            return Object.assign({}, state, {
                show     : true,
                message  : action.alert.message,
                isSuccess: action.alert.isSuccess
            })
        // when removing the alert, if you reset the success boolean it will change the visuals AS the alert is closing
        case ActionNames.REMOVE_ALERT:
            return {
                ...initialState,
                isSuccess: state.isSuccess
            }
        default:
            return state
    }
}